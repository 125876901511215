<template>
  <BaseSection
    :id="section.key"
    :is-visible="isSectionVisible"
    :is-modified="isDataChanged"
    :section="section"
    @onSectionSaveClick="save"
  >
    <template #section>
      <v-card-text class="mt-4">
        <div class="mb-5">
          <v-expand-transition>
            <v-textarea
              outlined
              name="input-7-4"
              label="Future finance goals"
              placeholder="Home renovation, Investment, start a family,
              purchase a new car, create investment portfolio"
              v-model="futureGoals.comment"
              :rows="4"
              auto-grow
              :disabled="FIELDS_DISABLED"
            ></v-textarea>
          </v-expand-transition>
        </div>
      </v-card-text>
    </template>
  </BaseSection>
</template>

<script>
import { mapGetters } from 'vuex';

import { clone } from '@/utils/object';

import SectionMixin from '@/mixins/SectionMixin';

import BaseSection from '@/components/ApplicationSections/BaseSection.vue';

export default {
  name: 'FutureGoalsSection',

  components: {
    BaseSection,
  },

  mixins: [SectionMixin],

  props: {
    existedData: {
      type: Object,
      default() {
        return {};
      },
    },

    section: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      isSectionVisible: false,

      initialFutureGoals: {},
      futureGoals: {},
    };
  },

  async created() {
    await this.checkIsSectionVisible();

    this.initialFutureGoals = clone(this.existedData);
    this.futureGoals = clone(this.existedData);

    this.$emit('componentReady');
  },

  computed: {
    ...mapGetters(['FIELDS_DISABLED']),
  },
};
</script>
