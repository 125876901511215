var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('BaseSection', {
    attrs: {
      "id": _vm.section.key,
      "is-visible": _vm.isSectionVisible,
      "is-modified": _vm.isDataChanged,
      "section": _vm.section
    },
    on: {
      "onSectionSaveClick": _vm.save
    },
    scopedSlots: _vm._u([{
      key: "section",
      fn: function fn() {
        return [_c('v-card-text', {
          staticClass: "mt-4"
        }, [_c('div', {
          staticClass: "mb-5"
        }, [_c('v-expand-transition', [_c('v-textarea', {
          attrs: {
            "outlined": "",
            "name": "input-7-4",
            "label": "Future finance goals",
            "placeholder": "Home renovation, Investment, start a family,\n            purchase a new car, create investment portfolio",
            "rows": 4,
            "auto-grow": "",
            "disabled": _vm.FIELDS_DISABLED
          },
          model: {
            value: _vm.futureGoals.comment,
            callback: function callback($$v) {
              _vm.$set(_vm.futureGoals, "comment", $$v);
            },
            expression: "futureGoals.comment"
          }
        })], 1)], 1)])];
      },
      proxy: true
    }])
  });
}
var staticRenderFns = []

export { render, staticRenderFns }